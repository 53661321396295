import { Pipe, PipeTransform } from '@angular/core';
@Pipe({  name: 'orderBy' })

// <mat-option *ngFor="let item of itemss | orderBy: {property: column, direction: direction}"" [value]="item">
//    {{ item.name ? item.name: item.identity}}
// </mat-option>
// Note: column=> any property, direction(1 or -1) => for ascending/descending

export class OrderByPipe implements PipeTransform {

    transform(records: Array<any>, args?: any): any {
        return records.sort(function(a, b){
            if(a[args.property] < b[args.property]){
                return -1 * args.direction;
            }
            else if( a[args.property] > b[args.property]){
                return 1 * args.direction;
            }
            else{
                return 0;
            }
        });
    };
}