<div class="p-2" style="overflow-x: hidden;">
  <div class="row">
    <div class="col d-flex justify-content-center">
        {{'Scegli con quale prodotto associare l\'articolo: ' + itemData?.description}}
    </div>
  </div>
  <form [formGroup]="product">
    <div class="row d-flex justify-content-center" style="margin: 20px 0;">
        <mat-form-field class="col-12" appearance="outline" style="width: 80%">
            <mat-label>Seleziona Prodotto</mat-label>
            <mat-select formControlName="productId" (selectionChange)="setProductRelData($event.value)"
                (openedChange)="focusProductFilter($event)">
                <mat-select-filter #productFilter [array]="productsFilterStrings" [displayMember]="'name'"
                    (filteredReturn)="setupProductSearch($event.value)" id="product-filter"></mat-select-filter>
                <cdk-virtual-scroll-viewport cdkScrollable appendOnly id="product-scroller" itemSize="48"
                    minBufferPx="200" style="height: 200px">
                    <div *cdkVirtualFor="let item of productsFilterStrings">
                        <mat-option value="{{item.id}}" [disabled]="item.id === 'noResults'">
                            {{item.name}}
                        </mat-option>
                    </div>
                </cdk-virtual-scroll-viewport>
            </mat-select>
            <mat-error>Selezionare un prodotto diverso da quello attualmente associato</mat-error>
        </mat-form-field>

        
    </div>
    <div class="row" style="margin-top: 15px;">
        <div class="col d-flex justify-content-center">
            <button mat-raised-button color="primary" class="msr-3" (click)="linkToProduct()"
                [disabled]="product.invalid">
                Salva
            </button>
            <a type="button" mat-stroked-button (click)="close()">Chiudi</a>
        </div>
    </div>
  
  </form>
</div>