import { Component, OnInit, HostListener } from '@angular/core';
import { Event, Router, NavigationStart } from '@angular/router';
import { PlatformLocation } from '@angular/common';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  @HostListener('mousewheel', ['$event']) onMouseWheelChrome(event: any) {
    this.disableScroll(event);
  }

  @HostListener('DOMMouseScroll', ['$event']) onMouseWheelFirefox(event: any) {
    this.disableScroll(event);
  }

  @HostListener('onmousewheel', ['$event']) onMouseWheelIE(event: any) {
    this.disableScroll(event);
  }



  currentUrl: string;

  constructor(
    public _router: Router,
    location: PlatformLocation,
  ) {
    this._router.events.subscribe((routerEvent: Event) => {
      if (routerEvent instanceof NavigationStart) {
        location.onPopState(() => {
          window.location.reload();
        });
        this.currentUrl = routerEvent.url.substring(
          routerEvent.url.lastIndexOf('/') + 1
        );
      }
      window.scrollTo(0, 0);
    });
  }

  ngOnInit(): void {
    console.log('env: ' + environment.name);
  }

  disableScroll(event: any) {
    if (event.srcElement.type === "number")
      event.preventDefault();
  }


}
