<app-header></app-header>
<app-sidebar></app-sidebar>
<section class="content">
  <div class="container-fluid">
    <div class="alert alert-warning" role="alert" *ngIf="msg != ''" >
      {{msg}}
    </div>

    <div class="main-con">
      <div class="body">
        <router-outlet></router-outlet>
      </div>
      
      </div>
  </div>
</section>

