import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthGuard } from './guard/auth.guard';
import { AuthService } from './service/auth.service';
import { DynamicScriptLoaderService } from './service/dynamic-script-loader.service';
import { throwIfAlreadyLoaded } from './guard/module-import.guard';
import { DataManagmentService } from '../shared/_service/data-managment.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
//import { LoaderInterceptor } from './interceptor/loader.interceptor';
//import { LoaderService } from './service/loader.service';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [
    AuthGuard,
    AuthService,
    DynamicScriptLoaderService,
    DataManagmentService,
    /*LoaderService,
      { provide: HTTP_INTERCEPTORS,
        useClass: LoaderInterceptor,
        multi: true
      }*/
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}

export * from './service/loader.service';
