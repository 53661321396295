import { JsonDeserializer } from 'jsona';
import { TJsonaModel, TJsonApiData, TJsonaRelationships } from 'jsona/lib/JsonaTypes';


export class CustomDeserializer extends JsonDeserializer {
  private forceRevIds: Set<string|number>;

  constructor(propertiesMapper, deserializeCache, options) {
    super(propertiesMapper, deserializeCache, options);
    this.forceRevIds = new Set<string|number>();
  }

  buildRelationsByData(data: TJsonApiData, model: TJsonaModel): TJsonaRelationships | null {
    const readyRelations = {};

    if (data.relationships) {
      for (let k in data.relationships) {
        const relation = data.relationships[k];

        if (Array.isArray(relation.data)) {
          readyRelations[k] = [];

          const relationDataLength = relation.data.length;
          let resourceIdObj;

          for (let i = 0; i < relationDataLength; i++) {
            resourceIdObj = relation.data[i];

            if (!resourceIdObj) {
              continue;
            }

            let dataItem = this.buildDataFromIncludedOrData(
              resourceIdObj.id,
              resourceIdObj.type,
              resourceIdObj.meta.target_revision_id ?? undefined
            );
            readyRelations[k].push(
              this.buildModelByData(dataItem, resourceIdObj)
            );
          }
        } else if (relation.data) {
          let dataItem = this.buildDataFromIncludedOrData(relation.data.id, relation.data.type, relation.data.meta.target_revision_id ?? undefined);
          readyRelations[k] = this.buildModelByData(dataItem, relation.data);
        } else if (relation.data === null) {
          readyRelations[k] = null;
        }

        if (relation.links) {
          const { setRelationshipLinks } = this.pm;
          if (setRelationshipLinks) {
            setRelationshipLinks(model, k, relation.links);
          }
        }

        if (relation.meta) {
          const { setRelationshipMeta } = this.pm;
          if (setRelationshipMeta) {
            setRelationshipMeta(model, k, relation.meta);
          }
        }
      }
    }

    if (Object.keys(readyRelations).length) {
      return <TJsonaRelationships>readyRelations;
    }

    this.forceRevIds.clear();

    return null;
  }

  buildDataFromIncludedOrData(id: string | number, type: string, revID?: number): TJsonApiData {

    if (this.preferNestedDataFromData) {
      const dataObject = this.buildDataInObject();
      const dataItemFromData = revID ? dataObject[type + id + revID] : dataObject[type + id];

      if (dataItemFromData) {
        return dataItemFromData;
      }
    }

    const includedObject = this.buildIncludedInObject(revID !== undefined, id);
    const dataItemFromIncluded = revID ? includedObject[type + id + revID] : includedObject[type + id];

    if (dataItemFromIncluded) {
      return dataItemFromIncluded;
    }

    if (!this.preferNestedDataFromData) {
      const dataObject = this.buildDataInObject();
      const dataItemFromData = revID ? dataObject[type + id + revID] : dataObject[type + id];

      if (dataItemFromData) {
        return dataItemFromData;
      }
    }

    return { id: id, type: type };
  }

  buildIncludedInObject(forceRev?: boolean, forceId?: string | number): { [key: string]: TJsonApiData } {
    if (!this.includedInObject || forceRev) {
      if (forceRev && forceId) {
        this.forceRevIds.add(forceId);
      }
      this.includedInObject = {};

      if (this.body.included) {
        let includedLength = this.body.included.length;
        for (let i = 0; i < includedLength; i++) {
          let item = this.body.included[i];

          if (forceRev && this.forceRevIds.has(item.id)) {
            this.includedInObject[item.type + item.id + item.attributes.drupal_internal__vid] = item;
          } else {
            this.includedInObject[item.type + item.id] = item;
          }
        }
      }
    }

    return this.includedInObject;
  }

  /* buildDataInObject(forceRev?: boolean): { [key: string]: TJsonApiData } {
    if (!this.dataInObject) {
      this.dataInObject = {};

      const { data } = this.body;
      const dataLength = data.length;

      if (data && dataLength) {
        for (let i = 0; i < dataLength; i++) {
          let item = data[i];
          if (forceRev) {
            this.dataInObject[item.type + item.id + item.attributes.drupal_internal__vid] = item;
          } else {
            this.dataInObject[item.type + item.id] = item;
          }
        }
      } else if (data) {
        if (forceRev) {
          this.dataInObject[data.type + data.id + data.attributes.drupal_internal__vid] = data;
        } else {
          this.dataInObject[data.type + data.id] = data;
        }
      }
    }

    return this.dataInObject;
  } */
}
