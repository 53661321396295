import { Component, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { DatePipe } from '@angular/common';

import { UrlService } from '../../_service/url.service';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { ApiService } from '../../_service/api.service';

@Component({
  selector: 'app-general-popup',
  templateUrl: './general-popup.component.html',
  styleUrls: ['./general-popup.component.sass'],
  providers: [DatePipe]
})
export class GeneralPopupComponent {
  kind: string;
  status: string;
  element: string;
  data = new Subject<{ action: string, kind?: string, label?: string, data?: any }>;
  popupData;

  constructor(
    public dialogRef: MatDialogRef<GeneralPopupComponent>,
    public cdref: ChangeDetectorRef,
    private url: UrlService,
    private apiSer: ApiService
  ) {
    dialogRef.disableClose = true;

    this.data.subscribe(value => {
      this.popupData = value;
      console.log(this.popupData);
    })
  }

  bindData(bindedData: { action: string, kind?: string, label?: string, data?: any }): void {
    console.log(bindedData)
    this.data.next(bindedData);
  }

  onNoClick(): void {
    this.dialogRef.close('onNoClick - close');
  }
  retriveBolla(id): void {
    console.log(id)
    if(id.hasOwnProperty('bolla')){
      console.log(id.bolla)
      console.log(id.bolla.file.uri.url)
      let url = id.bolla.file.uri.url;

      window.open(this.url.getBackendUrl() + url, "_blank");

    }
  }

  submitUserChoice(action?, kind?, choice?: boolean, result?: any): void {
    this.dialogRef.close({
      action: action ? action : null,
      kind: kind ? kind : null,
      status: choice ? choice : null,
      data: result ? result : null,
    });
  }

  close(): void {
    this.dialogRef.close('Ok - close');
  }
}
