<div class="file-drop-area">
  <button mat-raised-button color="primary" class="mx-2" (click)="fileInput.click()">Seleziona file</button>
  <button *ngIf="file && myFiles.length === 0 && allowFileOpening" mat-raised-button color="primary" class="mx-2"
    (click)="openFile()">Apri file</button>
  <button *ngIf="(myFiles.length || file) && allowFileDeletion" (click)="deleteFile()" mat-raised-button color="warn" class="mx-2">Cancella file</button>

  <span class="file-msg" *ngFor="let file of myFiles">
    <div class="me-2">{{ file.name }}</div>
  </span>
  <span class="file-msg" *ngIf="file && myFiles.length === 0">
    <div class="me-2">{{ file.filename }}</div>
  </span>
  <!-- <a mat-mini-fab color="warn" (click)="deleteFile()" class="delete-button" *ngIf="myFiles.length">
    <mat-icon class="col-white">delete_forever</mat-icon>
  </a> -->
  <input class="file-input" type="file" [multiple]=allowMultiple [accept]="allowedExtensions"
    (change)="onFileChange($event)" (dragover)="$event.preventDefault()" (drop)="$event.preventDefault()" #fileInput>
</div>