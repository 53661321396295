import { Component, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { DatePipe } from '@angular/common';

import { UrlService } from '../../_service/url.service';
import { Subject } from 'rxjs';
import { ApiService } from '../../_service/api.service';
import { ENDPOINTS } from '../../_service/url.service';

import { CustomJsona } from 'src/app/shared/Jsona extended/CustomJsona';

const dataFormatter = new CustomJsona();

@Component({
  selector: 'app-dialog-shortages',
  templateUrl: './dialog-shortages.component.html',
  styleUrls: ['./dialog-shortages.component.scss']
})
export class DialogShortagesComponent {
  kind: string;
  status: string;
  element: string;
  data = new Subject<{ action: string, kind?: string, label?: string, data?: any }>;
  popupData;

  lottiData: any;
  lottiLoaded: boolean = false;
  lottiSum = 0

  ORFData: any;
  ORFLoaded: boolean = false;
  ORFsum = 0 ;
  ORFincoming = 0 ;

  ORCData: any;
  ORCLoaded: boolean = false;
  ORCsum = 0 ;

  constructor(
    public dialogRef: MatDialogRef<DialogShortagesComponent>,
    public cdref: ChangeDetectorRef,
    private url: UrlService,
    private apiSer: ApiService
  ) {
    dialogRef.disableClose = true;

    this.data.subscribe(value => {
      this.popupData = value;
      console.log(this.popupData);
    })
  }

  bindData(bindedData: { action: string, kind?: string, label?: string, data?: any }): void {
    console.log(bindedData)
    this.data.next(bindedData);

    let itemsID = bindedData.data.id
    let itemsVersion = bindedData.data.id
    this.popupData.kind = 'view'
    let queryParamsORC = {
      'include':'ordine,ordine.cliente,ordine.fornitore_rev',
      'page[limit]': '10'
    };
    let queryParamsORF = {
      'include':'ordine,ordine.fornitore_rev',
      'page[limit]': '10'
    };
    let queryParamsLOTTI = {
      'page[limit]': '10',
      'filter[articolo.id]':itemsID
    };

    if (itemsID) {
      // queryParamsORC[`filter[filter-${'0'}][condition][path]`] = 'ordine.codice_interno';
      // queryParamsORC[`filter[filter-${'0'}][condition][operator]`] = 'STARTS_WITH';
      // queryParamsORC[`filter[filter-${'0'}][condition][value]`] = 'ORC-';
      queryParamsORC['filter[articolo_rev.id]'] = itemsID;
      
      queryParamsORF[`filter[filter-${'0'}][condition][path]`] = 'ordine.codice_interno';
      queryParamsORF[`filter[filter-${'0'}][condition][operator]`] = 'STARTS_WITH';
      queryParamsORF[`filter[filter-${'0'}][condition][value]`] = 'ORF-';
      queryParamsORF['filter[articolo_rev.id]'] = itemsID;

      // manca filtro su righe solo nuova o parziale
      // filtro su lotti solo codice 01

    }

    this.apiSer
      .getRequest(this.url.getBackendUrl() + ENDPOINTS.righe_ordini, null, queryParamsORC).subscribe(
        res=>{
          const jasonApiBaseData = dataFormatter.deserialize(res);
          console.log('ORDINI per articolo:')
          console.log(jasonApiBaseData)

          this.ORCData = jasonApiBaseData;
          

          for (let index = 0; index < this.ORCData.length; index++) {
            const element = this.ORCData[index]['quantita'];
            this.ORCsum += element
            
          }

          this.ORCLoaded = true;
          
        }
      )
    this.apiSer
      .getRequest(this.url.getBackendUrl() + ENDPOINTS.righe_ordini, null, queryParamsORF).subscribe(
        res=>{
          const jasonApiBaseData = dataFormatter.deserialize(res);
          console.log('ORDINI FORNITORI:')
          console.log(jasonApiBaseData)
          this.ORFData = jasonApiBaseData;

          for (let index = 0; index < this.ORFData.length; index++) {
            const element = this.ORFData[index]['quantita'];
            const income = this.ORFData[index]['stato_riga'] == 'nuova' ? this.ORFData[index]['quantita'] : this.ORFData[index]['quantita'] - this.ORFData[index]['evaso'];
            this.ORFsum += element
            this.ORFincoming += income

          }
          this.ORFLoaded = true;
          
        }
      )
    this.apiSer
      .getRequest(this.url.getBackendUrl() + ENDPOINTS.lotti, null, queryParamsLOTTI).subscribe(
        res=>{
          const jasonApiBaseData = dataFormatter.deserialize(res);
          console.log('LOTTI:')
          console.log(jasonApiBaseData)
          
          this.lottiData = jasonApiBaseData;
          this.lottiLoaded = true;
        }
      )
  }

  onNoClick(): void {
    this.dialogRef.close('onNoClick - close');
  }

  submitUserChoice(choice: boolean, action?, label?, kind?, result?: any): void {
    this.dialogRef.close({
      action: action ? action : null,
      kind: kind ? kind : null,
      label: label ? label : null,
      status: choice,
      data: result ? result : null,
    });
  }

}
