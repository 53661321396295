import { Directive, ElementRef, Host, HostListener, Optional } from '@angular/core';
import { NgControl } from '@angular/forms'

@Directive({
    selector: '[notdot]'
})
export class NotDotDirective {

    constructor(
        @Optional()
        @Host() private control: NgControl
    ) { }

    @HostListener('input', ['$event'])
    
    change($event) {

        const item = $event.target
        const value = item.value;
        const pos = item.selectionStart; //get the position of the cursor

        const matchValue = value.replace(/,/g, '.')
        if (matchValue != value) {
            if (this.control)
                this.control.control.setValue(matchValue, { emit: false });

            item.value = matchValue;
            item.selectionStart = item.selectionEnd = pos; //recover the position
        }
    }
}