import { Injectable,OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { ApiService } from '../_service/api.service';
import { UrlService } from '../_service/url.service';
import { ENDPOINTS } from '../_service/url.service';

import Jsona from 'jsona';
import { Subscription } from 'rxjs';
import { of, startWith, switchMap  } from 'rxjs';
import { Observable } from 'rxjs';

import { BehaviorSubject } from 'rxjs';

const dataFormatter = new Jsona();


@Pipe({ name: 'HttpCallPipe' })
@Injectable({
    providedIn: 'root'
  })

export class HttpCallPipe implements PipeTransform, OnDestroy {

    private subscription?: Subscription;
    private ORCsum$ = new BehaviorSubject<string | null>(null);

    // attenzione l'ngif non interpreta lo 0 come un valore:
    // vedi qui: https://stackoverflow.com/a/70939627
    // per questo convertiamo il risultato in stringa

    combineElements = [] as any[];
    ORCsum: number | null = null;
   
    constructor(
        private apiSer: ApiService,
        private url: UrlService
    ) {

    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    transform(object: any, args: any): Observable<string> | null {

        if (!object || !args) {
            return of(null);
          }

            let itemsID = object.id
            let queryParams: any = {};

            if (itemsID && args) {
                queryParams[`include`] = '';
                queryParams[`page[limit]`] = '5';
                queryParams['filter[articolo_rev.id]'] = itemsID;
                queryParams['filter[orc-f][group][conjunction]'] = 'AND';
                queryParams['filter[codint-f][condition][path]'] = 'ordine.codice_interno';
                queryParams[`filter[codint-f][condition][operator]`] = 'STARTS_WITH';
                queryParams[`filter[codint-f][condition][value]`] = args == 'clienti' ? 'ORC-' : 'ORF-';
                queryParams['filter[codint-f][condition][memberOf]'] = 'orc-f';

                queryParams['filter[status-f-con][group][conjunction]'] = 'OR';
                queryParams['filter[status-f-00][condition][path]'] = 'stato_riga';
                queryParams['filter[status-f-00][condition][operator]'] = '=';
                queryParams['filter[status-f-00][condition][value]'] = 'nuova';
                queryParams['filter[status-f-00][condition][memberOf]'] = 'status-f-con';
                queryParams['filter[status-f-01][condition][path]'] = 'stato_riga';
                queryParams['filter[status-f-01][condition][operator]'] = '=';
                queryParams['filter[status-f-01][condition][value]'] = 'parziale';
                queryParams['filter[status-f-01][condition][memberOf]'] = 'status-f-con';
            }

            this.ORCsum$.next(null); // Emette null inizialmente per mostrare il loader


            this.subscription = this.apiSer
                .getRequest(this.url.getBackendUrl() + ENDPOINTS.righe_ordini, null, queryParams)
                .pipe(
                    startWith(null),
                    switchMap(res => {
                        if (!res) {
                          return of(null);
                        }
              
                        if (res.links.next) {
                          this.loadNewData(res.links.next.href);
                          return this.ORCsum$.asObservable();
                        } else {
                          this.combineElements = [].concat(this.combineElements, dataFormatter.deserialize(res));
                          this.calculateORCsum();
                          return this.ORCsum$.asObservable();
                        }
                      })
                ).subscribe();

            return this.ORCsum$.asObservable();
      
    };

    private loadNewData(url: string) {

        return this.apiSer
            .getRequest(url)
            .subscribe(data => {
                if (data) {
                    this.combineElements = [].concat(this.combineElements, dataFormatter.deserialize(data));
                    if (data.hasOwnProperty('links') && data['links'].hasOwnProperty('next')) {
                        this.loadNewData(data['links']['next']['href'])
                    } else {
                        this.calculateORCsum();
                    }
                }
            })

    }

    private calculateORCsum() {
        if (this.combineElements.length == 0) {
            //this.ORCsum = 0;
            
            this.ORCsum$.next('0');
        } else {
            //TODO: calcolare correttamente le parziali
            this.ORCsum= 0;

            for (let index = 0; index < this.combineElements.length; index++) {
                const element = this.combineElements[index];
                console.log(element['stato_riga'])
                if(element['stato_riga'] == 'parziale'){
                    this.ORCsum += (element['quantita'] - ((element['in_lavorazione'] ?? 0) + (element['evaso']?? 0)))
                } else{
                    this.ORCsum += element['quantita']
                }
                
            }

            this.ORCsum$.next(String(this.ORCsum));
        }
        //console.log('ORCsum: ' + this.ORCsum);
    }
}