import { HttpClient, HttpHeaders} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/core/service/auth.service';

@Injectable({
  providedIn: 'root',
})
export class FileDownloadService {
  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) {}

  downloadFile(file_url, file_name?): void {
    const url = file_url; // URL del file
    const currentUser = this.authService.currentUserValue['token'];
    const headers =  {
      headers: new HttpHeaders({
        Authorization: `Bearer ${currentUser}`,
        Accept: `application/vnd.api+json`,
        'Content-Type': `application/vnd.api+json`
      })
    };

    this.http.get(url, {headers: headers.headers, responseType: 'blob' }).subscribe((blob) => {
      const a = document.createElement('a');
      const objectUrl = URL.createObjectURL(blob);
      a.href = objectUrl;
      a.download = file_name ?? 'file.pdf'; // Nome del file
      a.click();
      URL.revokeObjectURL(objectUrl);
    });
  }
}
