import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { AuthService } from '../service/auth.service';
import { Router } from '@angular/router';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private router: Router
  ) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // add authorization header with jwt token if available
    return next.handle(this.addTokenToRequest(request, this.authService.getToken())).pipe(
      catchError(err => {
        if (err.status === 0 || err.status === 401 || err.status === 403) {
          // if server return Unauthorized
          this.authService.logout();
          location.reload();
        } else if (err.status === 302) {
          this.router.navigate(['/pages/technical-works']);
        } else {
          const error = err;
          console.log(error)
          return throwError(error);
        }
      }));

  }

  private addTokenToRequest(request: HttpRequest<any>, token: string): HttpRequest<any> {
    let currentUser = this.authService.currentUserValue['token'];
    const urlDaEscludere = ["/logout", "/session", "/login", "/file", "/files"];
    const checkReqUrl = urlDaEscludere.some(url => request.url.includes(url));
    let headers: {};
    if (!checkReqUrl) {
      //console.log('jwt headers')
      headers = {
        setHeaders: {
          Authorization: `Bearer ${currentUser}`,
          Accept: `application/vnd.api+json`,
          'Content-Type': `application/vnd.api+json`
        },
      }
    }
    return request.clone(headers);
  }

}
