import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Page404Component } from './authentication/page404/page404.component';
import { AuthGuard } from './core/guard/auth.guard';
import { AuthLayoutComponent } from './layout/app-layout/auth-layout/auth-layout.component';
import { MainLayoutComponent } from './layout/app-layout/main-layout/main-layout.component';

const routes: Routes = [
  { path: '', redirectTo: 'app', pathMatch: 'full' },
  {
    path: '',
    component: MainLayoutComponent,
    
    children: [
      { path: '', redirectTo: 'app', pathMatch: 'full' },
      {
        path: 'app',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./_components/comps.module').then((c) => c.CompsModule)
      }
    ]
  },
  {
    path: 'authentication',
    component: AuthLayoutComponent,
    loadChildren: () =>
      import('./authentication/authentication.module').then(
        (m) => m.AuthenticationModule
      )
  },
  { path: '**', component: Page404Component }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    useHash: true, // attiva o disattiva l'uso del # nella url
    onSameUrlNavigation: 'reload'
  }
  )],
  exports: [RouterModule]
})
export class AppRoutingModule {}
