<ng-container *ngIf="this.popupData">
    <!-- GENERIC -->
    <ng-container *ngIf="popupData.action == 'generic'">
        <div mat-dialog-content [innerHTML]="popupData.label"></div>
        <div mat-dialog-actions>
            <button mat-button mat-stroked-button mat-dialog-close cdkFocusInitial
                (click)="submitUserChoice(true, popupData.action,'', popupData.kind, popupData.data)">Chiudi</button>
        </div>
    </ng-container>


    <ng-container *ngIf="popupData.action == 'shortages'">
        <div *ngIf="popupData.kind == 'loading'">
            <h1 mat-dialog-title>Operazione in corso</h1>
            <div mat-dialog-content>
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </div>
        </div>
        <div *ngIf="popupData.kind == 'view'">
            <h1 mat-dialog-title *ngIf="data">Carenze di magazzino per: {{popupData.data.description}}</h1>
            <div mat-dialog-content>
                <section class="border border-1 p-1 mb-4"
                    *ngIf="ORCData && ORCLoaded">
                    <div class="row pb-2">
                        <h1 mat-dialog-title>Riepilogo</h1>
                        <div class="col d-flex align-items-center justify-content-between">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th scope="col">Articolo</th>
                                        <th scope="col" class="text-center">Totale Ordini Cliente</th>

                                        <th scope="col" class="text-center">Merce Da ricevere</th>
                                        <th scope="col" class="text-center">Giacenza</th>
                                        <th scope="col" class="text-center">Disponibile per Trasmissione</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th scope="row" class="text-center">{{popupData.data.description}}</th>
                                        <td class="text-end">{{ORCsum}}</td>
                                        <td class="text-end">{{ORFincoming}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </section>
                <section class="border border-1 p-1 mb-4"
                    *ngIf="ORCData && ORCLoaded">
                    <div class="row pb-2">
                        <h1 mat-dialog-title>Dettaglio Ordini Clienti</h1>
                        <div class="col d-flex align-items-center justify-content-between">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th scope="col">Codice Riga</th>
                                        <th scope="col" class="text-center">Cliente</th>
                                        <th scope="col" class="text-center">Quantità</th>
                                        <th scope="col" class="text-center">Da evadere</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let choice of ORCData; let i=index">
                                        <th scope="row" class="text-center">{{choice['codice_interno']}}</th>
                                        <td class="text-end">{{choice['ordine']['cliente'] == null ? choice['ordine']['fornitore_rev']['ragione_sociale'] : choice['ordine']['cliente']['ragione_sociale']}}</td>
                                        <td class="text-end">{{choice['quantita']}}</td>
                                        <td class="text-end">{{choice['stato_riga'] == 'nuova' ? choice['quantita'] : choice['quantita'] - choice['evaso']}}</td>
                                        <!-- <td class="text-center">{{choice['codice_fornitore']}}</td>
                                        <td class="text-center">{{choice['codice_deposito']}}</td>
                                        <td class="text-end">{{choice['quantita']}}</td>
                                        <td class="text-end">{{choice['quantita'] - (choice['evaso'] ?? 0)}}</td> -->
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </section>
                <section class="border border-1 p-1 mb-4"
                    *ngIf="ORFData && ORFLoaded">
                    <div class="row pb-2">
                        <h1 mat-dialog-title>Dettaglio Ordini Fornitori</h1>
                        <div class="col d-flex align-items-center justify-content-between">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th scope="col">Codice Riga</th>
                                        <th scope="col" class="text-center">Fornitore</th>
                                        <th scope="col" class="text-center">Quantità</th>
                                        <th scope="col" class="text-center">Da ricevere</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let choice of ORFData; let i=index">
                                        <th scope="row" class="text-center">{{choice['codice_interno']}}</th>
                                        <td class="text-end">{{choice['ordine']['fornitore_rev']['ragione_sociale']}}</td>
                                        <td class="text-end">{{choice['quantita']}}</td>
                                        <td class="text-end">{{choice['stato_riga'] == 'nuova' ? choice['quantita'] : choice['quantita'] - choice['evaso']}}</td>
                                        <!-- <td class="text-center">{{choice['codice_fornitore']}}</td>
                                        <td class="text-center">{{choice['codice_deposito']}}</td>
                                        <td class="text-end">{{choice['quantita']}}</td>
                                        <td class="text-end">{{choice['quantita'] - (choice['evaso'] ?? 0)}}</td> -->
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </section>
                <section class="border border-1 p-1 mb-4"
                    *ngIf="lottiData && lottiLoaded">
                    <div class="row pb-2">
                        <h1 mat-dialog-title>Dettaglio lotti</h1>
                        <div class="col d-flex align-items-center justify-content-between">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th scope="col">Codice Lotto</th>
                                        <th scope="col" class="text-center">N Lotto Fornitore</th>
                                        <th scope="col" class="text-center">Codice Deposito</th>
                                        <th scope="col" class="text-center">Quantità</th>
                                        <th scope="col" class="text-center">Evadibile</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let choice of lottiData; let i=index">
                                        <th scope="row" class="text-center">{{choice['codice_interno']}}</th>
                                        <td class="text-center">{{choice['codice_fornitore']}}</td>
                                        <td class="text-center">{{choice['codice_deposito']}}</td>
                                        <td class="text-end">{{choice['quantita']}}</td>
                                        <td class="text-end">{{choice['quantita'] - (choice['evaso'] ?? 0)}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </section>
            </div>
        </div>
        <div mat-dialog-actions>
            <button mat-button mat-stroked-button mat-dialog-close cdkFocusInitial
                (click)="submitUserChoice(true, popupData.action,'', popupData.kind, popupData.data)">Chiudi</button>
        </div>
    </ng-container>
</ng-container>