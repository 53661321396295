<ng-container *ngIf="popupData.kind == 'supplier-order'">
  <h1 mat-dialog-title>Dettaglio Giacienza</h1>
  <div mat-dialog-content>
    <section class="border border-1 rounded p-1 mb-4" *ngIf="popupData['data'] && popupData['data'].length != 0">
      <div class="row pb-2">
        <div class="col d-flex align-items-center justify-content-between">


          <table class="table">
            <thead>
              <tr>
                <th scope="col">Rif Ordine</th>
                <th scope="col">Fornitore</th>
                <th scope="col">N Lotto Fornitore</th>
                <th scope="col">Quantità</th>
                <th scope="col">Data Scadenza</th>
                <th scope="col">Data Elaborazione</th>
                <th scope="col">Prezzo Acquisto</th>
                <th scope="col">Prezzo di Listino</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let choice of popupData['data']; let i=index">
                <th scope="row">{{choice['rifOrdine']}}</th>
                <td>{{choice['fornitore']}}</td>
                <td>{{choice['lottoIdFornitore']}}</td>
                <td>{{choice['quantita']}}</td>
                <td>{{choice['dataScadenza']}}</td>
                <td>{{choice['dataCarico']}}</td>
                <td>{{choice['prezzoAcquisto']}} €</td>
                <td>{{choice['prezzoListino']}} €</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
  </div>
  <div mat-dialog-actions>
    <button mat-button mat-dialog-close cdkFocusInitial>No</button>
    <button mat-button mat-dialog-close (click)="submitUserChoice(true)">Yes</button>
  </div>
</ng-container>

<ng-container *ngIf="popupData.kind == 'transfer-recap'">
  <div class="p-3">
    <div class="row">
      <div class="col-md-12">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th scope="col" class="text-center">Cor. Art.</th>
              <th scope="col" class="text-center">Articolo</th>
              <th scope="col" class="text-center">Lotto</th>
              <th scope="col" class="text-center">Quantità</th>
              <th scope="col" class="text-center">Prezzo Singolo</th>
              <th scope="col" class="text-center">Totale</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of popupData['data'].trasmissioni_righe">
              <th scope="row" class="text-center">{{item.riga.articolo_rev.codice_interno}}</th>
              <th scope="row" class="text-center">{{item.riga.articolo_rev.description}}</th>
              <th scope="row" class="text-center">{{item.lotto_rev.codice_fornitore}}</th>
              <td class="text-center">{{item.quantita}}</td>
              <td class="text-center">{{item.riga.prezzo}}</td>
              <td class="text-end">{{(+item.riga.prezzo * item.quantita) | currency:'EUR'
                }}</td>
            </tr>
          </tbody>
        </table>
        <div>
          <ul>
            <li *ngFor="let tr of popupData['data'].trasmissioni_righe" class="pb-2">
              <span class="pe-2">Data bolla: <strong>{{tr?.bolla?.data}}</strong></span>
              <span class="pe-2">File bolla: <strong>{{tr?.bolla?.numero}}</strong></span>
              <a (click)="retriveBolla(tr)" type="button" class="btn-loader " mat-stroked-button > Scarica Bolla</a>
              </li>
          </ul>
          
        </div>
      </div>
      <div class="col-md-12 d-flex align-items-center justify-content-between">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th scope="col" class="text-center">Totale Valore Trasmissione</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th class="text-center">{{+popupData['data'].totale | currency: 'EUR'}}</th>
            </tr>
          </tbody>
        </table>
      </div>

    </div>

    <div mat-dialog-actions>
      <button mat-button mat-stroked-button mat-dialog-close (click)="close()">Chiudi</button>
    </div>
  </div>

</ng-container>