import { Component, OnInit } from '@angular/core';
import { DataManagmentService } from 'src/app/shared/_service/data-managment.service';
import { delay } from 'rxjs';
import { SidebarService } from 'src/app/shared/_service/sidebar.service';
@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: []
})
export class MainLayoutComponent implements OnInit {
  msg = '';
  breadscrums = [
    {
      title: 'New Service',
      items: ['Services'],
      active: 'New Service'
    }
  ];
  showSidebar$ = true; // show sidebar by default
  constructor(
    private GLOBAL: DataManagmentService,
    private sidebarService: SidebarService
  ) {
    this.checkSidebarStatus();
  }
  ngOnInit(): void {
    this.GLOBAL.userType.subscribe((data) => {
      switch (data) {
        case 'dt':
          this.msg = data;
          break;
        case 'ba':
          this.msg = data;
          break;
        case 'mm':
          this.msg = data;
          break;
        case 'sup':
          this.msg = data;
          break;
        default:
          this.msg = data;
          break;
      }
    });
  }

  checkSidebarStatus(): void {
    /** attach a listener to catch the showSidebar status change
     * when a child component change this value.
     *
     * we are using .pipe(delay(0)) to defer this code to another Javascript Virtual Machine turn
     * to avoid the error "Expression has changed after it was checked"
     * bug explanation: https://blog.angular-university.io/angular-debugging/
     */
    this.sidebarService.showSidebar$.pipe(delay(0)).subscribe((value) => {
      this.showSidebar$ = value;
    });
  }
}
