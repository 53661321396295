import { AuthService } from '../service/auth.service';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        const isError = err
        let errorObject = {message:'', details:err};

        if (err.status === 401) {
          // auto logout if 401 response returned from api
          this.authenticationService.logout();
          location.reload();
        }

        if (err.status === 400) {
          console.log(err)
        }

        if (isError['error']['errors'] && isError['error']['errors'].length > 0){
          console.log(isError['error']['errors'][0].detail)
          errorObject.message = isError['error']['errors'][0].detail
        }else if(isError['error']['message'] != ''){
          errorObject.message = err['error']['message']
        }

        return throwError(() => errorObject);
      })
    );
  }
}
