import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { UrlService } from 'src/app/shared/_service/url.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';
import decode from 'jwt-decode';
import { DataManagmentService } from 'src/app/shared/_service/data-managment.service';
import { User } from '../models/user';
import { Role } from 'src/app/shared/_models/roles';
import { RUOLI_DISPONIBILI } from 'src/app/shared/_data/availableRoles';


const httpOptions = {
  headers: new HttpHeaders(
    { 'Content-Type': 'application/json' }
  ),
  //withCredentials: true
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private currentUserSubject: BehaviorSubject<any> = new BehaviorSubject({});
  public currentUser = this.currentUserSubject.asObservable();
  public isOkta: boolean = false;
  IsLogged$: BehaviorSubject<Boolean> = new BehaviorSubject(false);

  

  constructor(
    private http: HttpClient,
    public jwtHelper: JwtHelperService,
    private url: UrlService,
    private router: Router,
    private GLOBAL: DataManagmentService
  ) { }

  public get currentUserValue(): any {
    return this.currentUserSubject.value;
  }

  setCurrentUserValue(val) {
    const roles: Role[] = RUOLI_DISPONIBILI.sort((a,b) =>  a.priority - b.priority);
    //const token = val['csrf_token']
    console.log(val)
    console.log(roles)
    console.log(roles.length - 1)
    if (val) {
      const token = val['access_token']
      if (!this.jwtHelper.isTokenExpired(token)) {
        //const tokenPayload = decode(token);
        const user = {
          uid: val['current_user']['uid'],
          token: val['access_token'],
          logout_token: val['logout_token'] ? val['logout_token'] : '',
          csrf_token: val['csrf_token'] ? val['csrf_token'] : '',
        }
        this.currentUserSubject.next(user);
        console.log('userdata set')
        //if not empty get the user by its uid
        this.http.get(this.url.getBackendUrl() + '/api/utenti?filter[uid]=' + user['uid'] +'&include=ruoli').subscribe(user => {
          //console.log(user)

          let u: User = new User;
          u.id = val['current_user']['uid'] as number;
          u.specialPermissions = user['data'][0]['attributes']['can_access_manager_views'] ?? false;
          // extracting and setting the correct role
          let arr = user['data'][0]['relationships']['ruoli']['data'];

          if (arr) {
            let grantRole;
            roles.forEach((r, i) => {
              if (i == 0)
                // set the lowest permission
                grantRole = roles[roles.length - 1];

              arr.forEach(e => {
                if (e['meta']['drupal_internal__target_id'] === r.value) {
                  // look for the value property in the pre-defined array of Roles
                  let result = roles.find(item => item.value === e['meta']['drupal_internal__target_id']);
                  if (result && (result.priority < grantRole.priority)) {
                    // if the element has higher permission set the user role
                    grantRole = r
                  }
                }
              })
              
            })
            // EXEPTION FOR HCPHARMA - elevete role from content to manager
            if(u.specialPermissions && grantRole.value == 'content_editor'){
              let elevatePermission = roles.find(item => item.priority === 1);
              grantRole = elevatePermission
            }
            
            u.role = grantRole

          } else {
            // set the lowest role if not defined
            u.role = roles[roles.length - 1];
          }
          //console.log(u)
          u.displayName = user['data'][0]['attributes']['display_name']
          //console.log(u)
          // set user information at global level
          this.GLOBAL.setUserData(u)
        })
      }

    } else {
      //val not set
      this.router.navigate(['/']);
    }
  }

  public get enableMenu(): Boolean {
    return true;
  }


  public getToken(): string{
    let getToken;
    getToken = JSON.parse(localStorage.getItem('app_token'));
    if (getToken != null) {
      const token = getToken['access_token'];
      if (token != null && !this.jwtHelper.isTokenExpired(token)) {
        return token
      }
    } else return ''
  }

  public isAuthenticated(): Boolean {
    let getToken;
    getToken = JSON.parse(localStorage.getItem('app_token'));
    console.log(getToken)
    if (getToken != null) {
      const token = getToken['access_token'];
      if (token != null && !this.jwtHelper.isTokenExpired(token)) {
        // const user = {
        //   uid: getToken['current_user']['uid'],
        //   token: getToken['access_token'],
        //   logout_token: getToken['logout_token'] ? getToken['logout_token'] : '',
        //   //csrf_token: val['csrf_token'] ? val['csrf_token'] : '',
        // }
        // this.currentUserSubject.next(user);
        this.setCurrentUserValue(getToken);
        return true;
      } else {
        this.currentUserSubject.next({});
        localStorage.removeItem('app_token');
        return false;
      }
    } else {
      return false;
    }

  }

  login(name: string, pass: string): Observable<any> {
    return this.http
      .post<any>(
        this.url.getBackendUrl() + '/user/login?_format=json',
        {
          name,
          pass
        },
        httpOptions
      )
      .pipe(
        map((user: Response) => {
          console.log(user)
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('app_token', JSON.stringify(user));
          this.setCurrentUserValue(user);
          return user;
        })
      );
  }

  /*checkIfUserIsLogged() {
    this.http.get<Boolean>(this.url.getBackendUrl() + '/jwt/token', httpOptions)
      .pipe(
        map((token: any) => {
          console.log(token)
          if (token['token'] != '') {
            localStorage.setItem('app_token', JSON.stringify(token));
            this.setCurrentUserValue(token['token']);
            this.IsLogged$ = new BehaviorSubject(true);
          } else {
            this.IsLogged$ = new BehaviorSubject(false);
          }
        })
      )
  }*/

  logout() {
    console.log(this.currentUserValue)
    if (this.isAuthenticated) {
      localStorage.removeItem('app_token');
      sessionStorage.clear();
      this.router.navigate(['/authentication/login']);
      console.log('logout')

    } else {
      this.router.navigate(['/authentication/login']);
    }
  }
}
