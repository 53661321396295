
import { Component, Input } from '@angular/core';
import { AlertModel } from '../../_models/alert';

const ALERTS: AlertModel[] = [];

@Component({
    selector: 'app-alert',

    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.scss']
})
export class AlertComponent {

    @Input() alerts: AlertModel[] = [];

    constructor() {
        this.reset();
    }

    close(alert: AlertModel) {
        this.alerts.splice(this.alerts.indexOf(alert), 1);
    }

    reset() {
        this.alerts = Array.from(ALERTS);
    }
}
