import { NgModule } from "@angular/core";
import { FileUploadComponent } from "./file-upload/file-upload.component";
import { SharedModule } from "../shared.module";
import { GeneralPopupComponent } from './general-popup/general-popup.component';
import { DialogOverlayComponent } from "./dialog-overlay/dialog-overlay.component";
import { AlertComponent } from "./alert/alert.component";
import { GenericExporterTableComponent } from "./generic-exporter-table/generic-exporter-table.component";
import { GenericTableHeaderComponent } from "./generic-table-header/generic-table-header.component";
import { ProductSelectionComponent } from './product-selection/product-selection.component';
import { DialogShortagesComponent } from "./dialog-shortages/dialog-shortages.component";

@NgModule({
  declarations: [
    FileUploadComponent,
    GeneralPopupComponent,
    DialogOverlayComponent,
    AlertComponent,
    GenericExporterTableComponent,
    GenericTableHeaderComponent,
    ProductSelectionComponent,
    DialogShortagesComponent
  ],
  imports: [
    SharedModule
  ],
  exports: [
    FileUploadComponent,
    GeneralPopupComponent,
    AlertComponent,
    GenericExporterTableComponent,
    GenericTableHeaderComponent
  ]
})
export class SharedComponentsModule {}
