import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { User } from 'src/app/core/models/user';

@Injectable({
  providedIn: 'root'
})
export class DataManagmentService {

  private userKind: BehaviorSubject<string> = new BehaviorSubject('');
  userType = this.userKind.asObservable();

  private oktaFlow: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isOkta = this.oktaFlow.asObservable();

  private userData: BehaviorSubject<User> = new BehaviorSubject(new User);
  currentUserData = this.userData.asObservable();

  constructor() { }

  setMyteam(value: string){
    this.userKind.next(value);
  }

  getMyteam(){
    return this.userKind.value;
  }

  setOktaStatus(value: boolean){
    this.oktaFlow.next(value);
  }

  getOktaStatus(){
    return this.oktaFlow.value;
  }

  setUserData(currentData: User) {
    this.userData.next(currentData);
  }
}
