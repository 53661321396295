import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { UrlService } from "../../_service/url.service";

@Component({
  selector: "app-file-upload",
  templateUrl: "./file-upload.component.html",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: FileUploadComponent,
      multi: true,
    },
  ],
  styleUrls: ["./file-upload.component.scss"],
})
/**
 * this is a ControlValueAccessor component used to get
 * 1 or more uploaded file.
 */
export class FileUploadComponent implements ControlValueAccessor {
  @ViewChild('fileInput') inputElement: ElementRef<HTMLInputElement>;
  @Input() progress: any;
  @Input() set savedFile(file: any | undefined) {
    if (file) {
      this.file = file;
      this.fileURL = this.url.getBackendUrl() + file.uri.url;
    }
  };
  onChange!: Function;
  @Output() fileLoaded = new EventEmitter<File[]>();
  @Output() fileDeleted = new EventEmitter<void>();
  myFiles: any = [];
  @Input() allowMultiple = false;
  @Input() allowedExtensions: string = 'all';
  @Input() allowFileOpening = false;
  @Input() allowFileDeletion = false;

  file: any;
  fileURL: string;

  constructor(private host: ElementRef<HTMLInputElement>,
    private url: UrlService
  ) { }

  writeValue(value: null) {
    this.host.nativeElement.value = "";
    this.myFiles = [];
  }

  /**
   * function called every time che control value change
   */
  registerOnChange(fn: Function) {
    this.onChange = fn;
  }

  registerOnTouched(fn: Function) { }

  onFileChange(event: any) {
    this.myFiles = []; // restore array, clean view

    for (var i = 0; i < event.target.files.length; i++) {
      this.myFiles.push(event.target.files[i]);
    }

    /* this.onChange(this.myFiles); */
    this.fileLoaded.emit(this.myFiles);
  }

  openFile() {
    window.open(this.fileURL, '_blank');
  }

  deleteFile() {
    if (this.myFiles.length) {
      this.myFiles = [];
      this.inputElement.nativeElement.value = null;
    }
    this.fileDeleted.emit();
  }
}
