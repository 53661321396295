import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

const ip = '';
const localhost = ip != '' ? ip : 'localhost';
const localRest = 'http://' + localhost + ':3000'; // Json Server

const backendDev = 'https://';
const backendProd = 'https://be.gestionale.hcpharma.it';

const backendStaging = 'https://staging.be.gestionale.hcpharma.it';

@Injectable({
  providedIn: 'root'
})
export class UrlService {

  constructor() {
    console.log(location.origin);
    console.log(location.href);
    console.log(location.pathname);
  }

  public getBaseUrl(){
    let url;
    switch (environment.name) {
      case 'dev':
        url = localRest
        break;
    
      default:
        break;
    }
    return url;
  }
// API base url
  public getBackendUrl(){
    let url;
    switch (environment.name) {
      case 'dev':
        url = backendDev
        break;
      case 'staging':
        url = backendStaging
        break;
      case 'prod':
        url = backendProd
        break;
      case 'test':
        url = backendProd
        break;
    
      default:
        break;
    }
    return url;
  }
}

/*
** Lista delle url di controllo
**
*/

export let urlList = {
  login: {
    prod: '/gecko',
    dev: backendDev + '/user/login?_format=json'
  }
};

export let ENDPOINTS = {
  users: '/api/users',
  login: '/user/login?_format=json',
  logout: '/user/logout?_format=json',
  articoli: '/api/articoli',
  prodotti: '/api/prodotti',
  farmadati: '/farmadati/ricerca',
  farmadati_aliquote: '/farmadati/aliquote_iva',
  farmadati_contenitori: '/farmadati/contenitori',
  fornitori:'/api/fornitori',
  clienti:'/api/clienti',
  indirizzi:'/api/indirizzi',
  ordini_fornitori:'/api/ordini_fornitori',
  ordini_clienti:'/api/ordini_clienti',
  righe_ordini:'/api/righe',
  trasmissioni:'/api/trasmissioni',
  lotti:'/api/lotti',
  righe_trasmissioni:'/api/trasmissioni_righe',
  offerte: '/api/offerte',
  righe_offerte: '/api/offerte_righe',
  agenti: '/api/agenti',
  ricezioni: '/api/ricezioni',
  righe_ricezioni: '/api/ricezioni_righe',
  files: '/api/files',
  bolle: '/api/bolle',
  revisioni: '/lista-revisioni'
};

export let FIELDS = {
  divisione: '/api/fields_storage_config/e3bc9570-bf01-4892-94f2-6cf706433cdf',
  aree: '/api/fields_storage_config/9d6b59cf-801b-43d7-9648-c836f9303fe0',
  umb: '/api/fields_storage_config/b7795afb-4243-46ca-9e1d-bd31fbd50361',
}
export let FARMADATI_DOCUMENTS = {
  get_doc:'/farmadati/get_doc/',
  foglio_illustrativo_parafarmaci: '/farmadati/foglio_illustrativo_parafarmaci',
  scheda_sicurezza_parafarmaci: '/farmadati/scheda_sicurezza_parafarmaci',
  scheda_tecnica_parafarmaci: '/farmadati/scheda_tecnica_parafarmaci',
  foglio_illustrativo_medicinali: '/farmadati/foglio_illustrativo_medicinali',
  immagini_medicinali: '/farmadati/immagini_medicinali',
  scheda_tecnica_rcp: '/farmadati/scheda_tecnica_rcp'
}