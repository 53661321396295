import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { MaterialModule } from './material.module';
import { FeatherIconsModule } from './_components/feather-icons/feather-icons.module';
import {ScrollingModule} from '@angular/cdk/scrolling';
import { MatSelectFilterModule } from 'mat-select-filter';

import { DecodeHtmlString } from './_pipes/decodeHTML.pipe';
import { OrderByPipe } from './_pipes/orderBy.pipe';
import { HttpCallPipe } from './_pipes/httpCall.pipe';

@NgModule({
  declarations: [
    DecodeHtmlString,
    OrderByPipe,
    HttpCallPipe
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MaterialModule,
    ScrollingModule,
    MatSelectFilterModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MaterialModule,
    FeatherIconsModule,
    ScrollingModule,
    MatSelectFilterModule,
    DecodeHtmlString,
    OrderByPipe,
    HttpCallPipe
  ]
})
export class SharedModule {}
