import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LooseObject } from '../../_service/filters.service';
import { CdkScrollable, ScrollDispatcher } from '@angular/cdk/scrolling';
import { Subject, Subscription, debounceTime } from 'rxjs';
import { MatSelectFilterComponent } from 'mat-select-filter';

import { ApiService } from '../../_service/api.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogOverlayComponent } from '../dialog-overlay/dialog-overlay.component';
import { Router } from '@angular/router';
import { ENDPOINTS, UrlService } from '../../_service/url.service';
import { createProductIdValidator } from '../../_validators/product_id.directives';

import { CustomJsona } from 'src/app/shared/Jsona extended/CustomJsona';

const dataFormatter = new CustomJsona();

@Component({
  selector: 'app-product-selection',
  templateUrl: './product-selection.component.html',
  styleUrls: ['./product-selection.component.sass']
})
export class ProductSelectionComponent implements OnInit {
  product: FormGroup = this.fb.group({
    productId: ['', [Validators.required]],
    productRel: [],
  });
  isFiltering = false;
  dialogRef: any;

  products = [];
  itemData;

  @ViewChild('productFilter') productsFilter: MatSelectFilterComponent;
  productFilterSubscription: Subscription;
  productsFilterStrings = [];
  isProductsListLoaded = false;
  nextProductsCall;

  constructor(
    private fb: FormBuilder,
    private scrollDispatcher: ScrollDispatcher,
    private cd: ChangeDetectorRef,
    private apiSer: ApiService,
    private dialog: MatDialog,
    private router: Router,
    private url: UrlService,
    @Inject(MAT_DIALOG_DATA) public data: {data: any},
    public prodSelectionRef: MatDialogRef<ProductSelectionComponent>
  ) {}

  ngOnInit(): void {
    this.apiSer
      .getRequest(this.url.getBackendUrl() + ENDPOINTS.prodotti, null, { 'page[limit]': 20 })
      .subscribe(res => {
        const data = dataFormatter.deserialize(res) as any[];

        this.products = [].concat(this.products, res.data);
        this.productsFilterStrings = res.data.filter((elem) => {
          if (!elem) {
            return false;
          }
          return true;
        }).map((elem) => {
          //console.log(elem)
          return {
            id: elem.id,
            name: `${elem['attributes']['name'] ?? ''} ${'(' + elem['attributes']['codice_interno'] + ')' ?? ''}`,
            rel: elem.relationships.articoli.data
          }
        });
        if (res.hasOwnProperty('links') && res['links'].hasOwnProperty('next')) {
          this.isProductsListLoaded = true
          this.nextProductsCall = res['links']['next']['href'];
        } else {
          this.nextProductsCall = '';
          this.isProductsListLoaded = true
        }
      })

    this.scrollDispatcher.scrolled(100).subscribe((event: CdkScrollable) => {
      if (event) {
        let scrollerId = event.getElementRef().nativeElement.id;
        let bottomOffset = event.measureScrollOffset('bottom');
        if (bottomOffset === 0 && !this.isFiltering) {
          if (scrollerId.includes('product-scroller')) {
            let index = scrollerId.split('-')[2];
            let withFilters = this.productsFilter.searchForm.value;
            if (this.nextProductsCall && withFilters.value) {
              this.cd.detectChanges(); // Trigger change detection to show spinner
              this.loadMoreProducts();
            } else if (this.nextProductsCall && !withFilters.value) {
              this.cd.detectChanges(); // Trigger change detection to show spinner
              this.loadMoreProducts();
            }
          }
        }
      }
    });

    this.itemData = this.data.data.article;
    
    if (this.itemData.prodotto) {
      this.product.get('productId').addValidators(createProductIdValidator(this.itemData.prodotto.id));
    }
  }

  openDialog(
    enterAnimationDuration: string,
    exitAnimationDuration: string,
    data?: any
  ): void {
    this.dialogRef = this.dialog.open(DialogOverlayComponent, {
      width: '600px',
      height: '300px',
      //maxWidth: '100vh',
      //maxHeight: '100vh',
      hasBackdrop: true,
      enterAnimationDuration,
      exitAnimationDuration,
      data: { data }

    });
    (this.dialogRef.componentInstance as DialogOverlayComponent).bindData(data as any);
    this.dialogRef.afterClosed().subscribe((result) => {
      console.log(result)
      if (result && result['choice']) {
      }

      if (result && result.action == 'create') {
        this.close();
      }
      if (result && result.kind == 'noresults') {

      }
      if (result && result.action == 'update') {
        this.close();
      }
  })
}

updateDialogInformation(status: any): void {
  if (this.dialogRef && this.dialogRef.componentInstance) {
    // Access the component instance of the dialog
    const dialogComponent = this.dialogRef.componentInstance;
    // Update the properties or call a method on the component
    dialogComponent.popupData = status;
  }
}

  // getter for easier access
  get productFormValue() {
    return this.product.getRawValue();
  }

  setProductRelData(value) {
    const finder = this.productsFilterStrings.find(elem => elem.id === value);
    console.log(finder)
    this.product.get('productRel').patchValue(finder['rel'])
    console.log(this.productFormValue['productRel'])
  }

  async linkToProduct() {
    this.openDialog('100ms', '100ms', { action: 'create', kind: 'loading' });
    const article = this.data.data.article
    if (article.prodotto) {
      await this.deleteRelationship(article.id, article.prodotto.id);
    }
    let singleRel: LooseObject = {};
    singleRel.type = 'prodotti'
    singleRel.id = this.productFormValue['productId']
    //singleRel.articoli = { type: 'articoli', id: this.requestId }
    singleRel.articoli = [].concat([], this.productFormValue['productRel']);
    singleRel.articoli.push({ type: 'articoli', id: article.id });
    singleRel.relationshipNames = []
    singleRel.relationshipNames.push('articoli')
    let bodyJosnA = dataFormatter.serialize({ stuff: singleRel, includeNames: [] });

    this.apiSer
      .patchRequest(this.url.getBackendUrl() + ENDPOINTS.prodotti + '/' + this.productFormValue['productId'], bodyJosnA).subscribe(
        (res) => {
          console.log(res)
          this.prodSelectionRef.close('Article updated');
          this.updateDialogInformation({ action: 'create', kind: 'done', label: 'Articolo Aggiornato' })
        }
      )
  }

  deleteRelationship(articleID: string, productID: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const deleteItem = {
        data: [{ type: 'articoli', id: articleID }]
      };

      this.apiSer
        .deleteRequest(this.url.getBackendUrl() + ENDPOINTS.prodotti + '/' + productID + '/relationships/articoli', null, deleteItem)
        .subscribe({
          next: () => {

          },
          error: (err) => {
            reject(err);
          },
          complete: () => {
            resolve();
          }
        })
    })
  }

  private updateProductList(value: string) {
    let currentClientId = this.product.get('productId').value;
    const currentItem = this.productsFilterStrings.find(elem => elem.id === currentClientId);

    this.products = [];
    this.productsFilterStrings = [];
    let filterParam = {} as LooseObject;
    filterParam['filter[title-filter][condition][path]'] = 'name';
    filterParam['filter[title-filter][condition][operator]'] = 'CONTAINS';
    filterParam['filter[title-filter][condition][value]'] = value;
    // get the bookings
    this.apiSer
      //edit
      .getRequest(
        this.url.getBackendUrl() + ENDPOINTS.prodotti, null, filterParam)
      .subscribe((res) => {
        this.products = [].concat(this.products, res.data);
        if (this.products.length) {
          this.productsFilterStrings = this.products.filter((elem) => {
            if (!elem) {
              return false;
            }
            return true;
          }).map((elem) => {
            return {
              id: elem.id,
              name: `${elem['attributes']['name'] ?? ''} ${'(' + elem['attributes']['codice_interno'] + ')' ?? ''}`,
              rel: elem.relationships.articoli.data
            }
          });
        } else {
          this.productsFilterStrings = [{
            id: 'noResults',
            name: 'Nessun risultato'
          }];
        }

        if (currentItem) {

          let index = this.productsFilterStrings.findIndex(elem => elem.id === currentItem.id);
          if (index === -1 || index > 4) {
            console.log(currentItem)
            this.productsFilterStrings.unshift({
              id: currentItem.id,
              name: `${currentItem['attributes']['name'] ?? ''}`,
              rel: currentItem.relationships.articoli.data
            });
          }
        }

        if (res.hasOwnProperty('links') && res['links'].hasOwnProperty('next')) {
          this.nextProductsCall = res['links']['next']['href'];
        } else {
          this.nextProductsCall = '';
        }

        //this.isFiltering = false;
      });
  }

  setupProductSearch(value) {
    if (this.productsFilter && !this.productFilterSubscription) {
      this.productFilterSubscription = this.productsFilter.searchForm.valueChanges
        .pipe(debounceTime(500))
        .subscribe((value) => {
          this.isFiltering = true;
          this.updateProductList(value.value);
        });
    }
  }

  loadMoreProducts() {
    this.apiSer.getRequest(this.nextProductsCall, null).subscribe(res => {
      if (res) {
        this.products = [].concat(this.products, res.data);
        this.productsFilterStrings = this.products.filter((elem) => {
          if (!elem) {
            return false;
          }
          return true;
        }).map((elem) => {
          return {
            id: elem.id,
            name: `${elem['attributes']['name'] ?? ''} ${'(' + elem['attributes']['codice_interno'] + ')' ?? ''}`,
            rel: elem.relationships.articoli.data
          }
        });
        if (res.hasOwnProperty('links') && res['links'].hasOwnProperty('next')) {
          this.nextProductsCall = res['links']['next']['href'];
        } else {
          this.nextProductsCall = '';
        }
      }

      if (this.productsFilter) {
        setTimeout(() => { this.productsFilter.localSpinner = false; }, 500);
      }
    })
  }

  focusProductFilter(isOpened: any) {
    if (isOpened) {
      const elem = document.getElementById('product-filter');
      const input = elem.firstElementChild.firstElementChild.firstElementChild as HTMLInputElement;
      input.focus();
    }
  }

  close() {
    this.prodSelectionRef.close('Button click');
  }
}
