import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate
} from '@angular/router';

import { AuthService } from '../service/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(public authService: AuthService, public router: Router) {}

  canActivate(): boolean {
    console.log('canActivate')
    if (!this.authService.isAuthenticated()) {
      console.log('cant activate')
      this.router.navigate(['/authentication/login']);
      return false;
    }
    return true;
  }
}
