import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { map, catchError, tap, retry } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class ApiService {

  constructor(private http: HttpClient) { }

  private extractData(res: Response) {
    let body = res;
    return body || {};
  }

  public getRequest(url: string, head?: any, queryParam?: any, otherParam?: any): Observable<any> {
    let optionsGroup = {};
    const headers = head ? head : {
      headers: new HttpHeaders({
        "Accept": "application/vnd.api+json",
        "Content-Type": "application/vnd.api+json",
      })
    };
    const params = queryParam ? queryParam : {};

    optionsGroup = {
      headers: headers.headers,
      params: params
    };

    return this.http.get(url, optionsGroup).pipe(
      map((this.extractData as any)),
      retry(1), catchError(this.handleError<any>('postRequest: ' + url))
      )
  }

  public postRequest(url: string, body: any, options?: any): Observable<any> {
    const optionsGroup = options ? options : {
      headers: new HttpHeaders({
        "Accept": "application/vnd.api+json",
        "Content-Type": "application/vnd.api+json",
      })
    };
    return this.http.post<any>(url, body, optionsGroup).pipe(
      tap(() => console.log('postRequest')),
      retry(1), catchError(this.handleError<any>('postRequest: ' + url))
    );
  }

  public putRequest(url: string, body: any, options?: any): Observable<any> {
    const optionsGroup = options ? options : {};
    return this.http.put<any>(url, body, optionsGroup).pipe(
      tap(() => console.log('putRequest')),
      retry(1), catchError(this.handleError<any>('postRequest: ' + url))
    );
  }

  public deleteRequest(url: string, headers?: any, body?: any, ): Observable<any> {
    const optionsHeaders = headers ? headers : {
      headers: new HttpHeaders({
        "Content-Type": "application/vnd.api+json",
      })
    };
    const hasBody = body ? body : {};
    const optionsGroup = {
      headers: optionsHeaders.headers,
      body: hasBody
    }
    return this.http.delete<any>(url, optionsGroup ).pipe(
      tap(() => console.log('deleteRequest')),
      retry(1), catchError(this.handleError<any>('postRequest: ' + url))
    );
  }

  public patchRequest(url: string, body: any, options?: any): Observable<any> {
    const optionsGroup = options ? options : {
      headers: new HttpHeaders({
        "accept": "application/vnd.api+json",
        "content-type": "application/vnd.api+json"
      })
    };
    return this.http.patch<any>(url, body, optionsGroup).pipe(
      tap(() => console.log('patchRequest')),
      retry(1), catchError(this.handleError<any>('postRequest: ' + url))
      )
  }


  private handleError<T>(operation = 'operation', result?: any) {
    return (error: any): Observable<any> => {
      // console.error(error); // log to console instead
      console.error(`${operation} failed: ${error.message}`);
      // Let the app keep running by returning an empty result.
      result = {message: error.message }
      //return of(result);
      return throwError(() => {
        return (result);
      });
    };
  }

  handleErrorNo(error: any) {
    console.log(error)
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    //console.error(errorMessage);
    return throwError(() => {
      return errorMessage;
    });
  }
}
