import { Jsona } from 'jsona';
import { IDeserializeCacheConstructor, IJsonDeserializerConstructor, IJsonPropertiesMapper, IModelPropertiesMapper, IModelsSerializerConstructor } from 'jsona/lib/JsonaTypes';
import { CustomDeserializer } from './CustomDeserializer';

export class CustomJsona extends Jsona {
  public JsonDeserializer: IJsonDeserializerConstructor = CustomDeserializer;

  constructor(params?: {
    modelPropertiesMapper?: IModelPropertiesMapper,
    jsonPropertiesMapper?: IJsonPropertiesMapper,
    DeserializeCache?: IDeserializeCacheConstructor,
    ModelsSerializer?: IModelsSerializerConstructor,
    JsonDeserializer?: IJsonDeserializerConstructor,
}) {
    super(params);
  }
}