import { LOCALE_ID, NgModule } from '@angular/core';
import localeIt from '@angular/common/locales/it'; // Lingua
import { HashLocationStrategy, registerLocaleData } from '@angular/common';
import { LocationStrategy, Location, PathLocationStrategy } from '@angular/common';
// Language Location
registerLocaleData(localeIt, 'it');
import { OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { SharedComponentsModule } from './shared/_components/shared-components.module';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './layout/header/header.component';
import { PageLoaderComponent } from './layout/page-loader/page-loader.component';
import { SidebarComponent } from './layout/sidebar/sidebar.component';
import { AuthLayoutComponent } from './layout/app-layout/auth-layout/auth-layout.component';
import { MainLayoutComponent } from './layout/app-layout/main-layout/main-layout.component';

import { ErrorInterceptor } from './core/interceptor/error.interceptor';
import { JwtInterceptor } from './core/interceptor/jwt.interceptor';
//import { LoaderInterceptor } from './core/interceptor/loader.interceptor';

import { NotDotDirective } from './shared/_directives/notdot.directive';

import { ClickOutsideModule } from 'ng-click-outside';
import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientJsonpModule
} from '@angular/common/http';
import { WINDOW_PROVIDERS } from './core/service/window.service';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { JwtHelperService, JWT_OPTIONS } from '@auth0/angular-jwt';
import { DataManagmentService } from './shared/_service/data-managment.service';
import { LoaderService } from './core/core.module';

import { JsonapiBootstrap } from 'ngx-jsonapi';

import { environment } from '../environments/environment';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    PageLoaderComponent,
    SidebarComponent,
    AuthLayoutComponent,
    MainLayoutComponent,
    NotDotDirective
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    HttpClientJsonpModule,
    ClickOutsideModule,
    LoadingBarHttpClientModule,
    NgScrollbarModule,
    CoreModule,
    SharedModule,
    SharedComponentsModule
  ],
  providers: [
    DataManagmentService,
    [Location, { provide: LocationStrategy, useClass: HashLocationStrategy }],
    LoaderService,
    //{ provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    JwtHelperService,
    WINDOW_PROVIDERS,
    {
      provide: LOCALE_ID,
      useValue: 'it'
    },
    { provide: OWL_DATE_TIME_LOCALE, useValue: 'it' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  public constructor() {
    JsonapiBootstrap.bootstrap({
         user_config: { url: environment.name == 'dev'? environment.name : ''  }
    });
}
}
