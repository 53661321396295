import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {
/**
 * Service that permits to handle if the sidebar
 * is visible or not in a specific route.
 * Predefinited case is true.
 */

  sidebarStatus: BehaviorSubject<boolean> = new BehaviorSubject(true); // show sidebar by default
  showSidebar$  = this.sidebarStatus.asObservable();

  private test: BehaviorSubject<string> = new BehaviorSubject('testo di esempio');
  test$ = this.test.asObservable();

  constructor() { }

  editSidebarStatus(value:boolean) {
    this.sidebarStatus.next(value);
  }

  editTestStatus(value:any) {
    this.test.next(value);
  }
}
